<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h3>Welcome, </h3>
        <div class="alert alert-info">
          {{userInfo}}
        </div>
        <p>{{userPoolId}}</p>
        <p>{{userGroup}}</p>
        <router-link to="/logout">
        Logout
        </router-link>

        

      </div>
    </div>
  </div>
</template>

<script>
import { store } from '../store'

export default {
  name: 'About',
  data: function() {
    return {
      userPoolId: window._config.VUE_APP_COGNITO_USERPOOL_ID,
      userInfo: store.cognitoInfo,
      useruser: store.group
    }
  }
}
</script>

<style>
</style>